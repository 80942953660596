<script setup lang="ts">
  import { unreadCountStore } from '~/store'
  const props = defineProps<{
    pageType: string,
  }>()

  const navItemArr: Array<{
    name: string,
    path: string,
    iconName: string,
    displayName: string,
  }> = [
    {
      name: 'guard-home',
      path: '/guard-home',
      iconName: 'home',
      displayName: 'ホーム',
    },
    {
      name: 'calendar',
      path: '/calendar',
      iconName: 'calendar_month',
      displayName: 'カレンダー',
    },
    {
      name: 'contact',
      path: '/contact',
      iconName: 'email',
      displayName: '連絡する',
    },
    {
      name: 'settings',
      path: '/settings',
      iconName: 'more_horiz',
      displayName: 'その他',
    },
  ]
</script>
<template>
  <div
    class="
      common-background-leaf
      h-12
    "
  >
    <ul
      class="
        flex
        justify-evenly
      "
    >
      <li
        v-for="navItem in navItemArr"
        :key="navItem.name"
        class="
          mt-1
          mb-1
          md:mb-2
          text-center
        "
      >
        <div
          class="
            relative
          "
        >
          <nuxt-link
            :class="props.pageType === navItem.name ? 'text-white' : 'text-gray-300'"
            :to="navItem.path"
          >
            <div
              class="
                material-icons
              "
            >
              {{navItem.iconName}}
            </div>
            <div
              class="
                -mt-1
                text-xs
                md:text-sm
              "
            >
              {{navItem.displayName}}
            </div>
          </nuxt-link>
          <common-unread-badge
            v-if="navItem.name=='guard-home' && unreadCountStore.getNoticeCount + unreadCountStore.getEventCount > 0"
          >
            <span
              v-if="unreadCountStore.getNoticeCount + unreadCountStore.getEventCount > 0"
            >
              {{ unreadCountStore.getNoticeCount + unreadCountStore.getEventCount }}
            </span>
          </common-unread-badge>
        </div>
      </li>
    </ul>
  </div>
</template>
